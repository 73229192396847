<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Captains</span>
    </el-card>
    <div class="table-container">
      <el-table
        ref="CaptainTable"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Name" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="Nickname" align="center">
          <template slot-scope="scope">{{ scope.row.nickname }}</template>
        </el-table-column>
        <el-table-column label="Phone" align="center">
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="Email" align="center">
          <template slot-scope="scope">{{ scope.row.email }}</template>
        </el-table-column>
        <el-table-column label="Address" align="center">
          <template slot-scope="scope">{{ scope.row.address }}</template>
        </el-table-column>
        <el-table-column label="City" align="center">
          <template slot-scope="scope">{{ scope.row.city }}</template>
        </el-table-column>
        <el-table-column label="Description" align="center">
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column>
        <el-table-column label="Thumbnail" align="center">
          <template slot-scope="scope"
            ><img :src="scope.row.communityThumbnail" style="width: 100%"
          /></template>
        </el-table-column>
        <el-table-column label="Operations" align="center">
          <template slot-scope="scope">
            <p>
              <el-button size="mini" @click="sendNotification(scope.row)"
                >Send Notification To Community</el-button
              >
            </p>
            <p>
              <el-button size="mini" @click="updateCommunity(scope.row)"
                >Update Community</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="'Send Notification To Community: ' + notificationCaptainName"
        :visible.sync="notificationVisible"
        width="30%"
      >
        <notification-form type="captain" :id.sync="notificationCaptainId" @finish="notificationVisible=false;"></notification-form>
      </el-dialog>

      <el-dialog
        title="Update Community"
        :visible.sync="updateCommunityVisible"
        width="30%"
      >
        <el-form
          ref="updateCommunityForm"
          :model="updateCommunityParam"
          label-width="150px"
        >
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="updateCommunityParam.name"
              auto-complete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="Description" prop="description">
            <el-input
              v-model="updateCommunityParam.description"
              auto-complete="off"
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item label="Picture" prop="thumbnail">
            <single-upload
              v-model="updateCommunityParam.thumbnail"
              auto-complete="off"
            ></single-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateCommunityVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitUpdateCommunity()"
            >Update</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { fetchActive, updateCaptainCommunity } from '@/api/captain';
import SingleUpload from '@/components/Upload/singleUpload';
import NotificationForm from '@/views/notification/NotificationForm';

export default {
  name: 'index',
  components: { SingleUpload, NotificationForm },
  data() {
    return {
      list: null,
      listLoading: true,
      updateCommunityParam: {
        captainId: null,
        name: null,
        description: null,
        thumbnail: null,
      },
      updateCommunityVisible: false,

      notificationVisible: false,
      notificationCaptainName: null,
      notificationCaptainId: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      fetchActive().then((response) => {
        this.listLoading = false;
        this.list = response.data;
      });
    },
    sendNotification(captain) {
      this.notificationCaptainId = captain.id;
      this.notificationCaptainName = captain.name;
      this.notificationVisible = true;
    },
    updateCommunity(captain) {
      this.updateCommunityParam.captainId = captain.id;
      this.updateCommunityParam.name = captain.name;
      this.updateCommunityParam.description = captain.description;
      this.updateCommunityParam.thumbnail = captain.communityThumbnail;
      this.updateCommunityVisible = true;
    },
    submitUpdateCommunity() {
      this.$confirm('Update Community?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        updateCaptainCommunity(this.updateCommunityParam).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
        });
        this.updateCommunityVisible = false;
        this.getList();
      });
    },
  },
};
</script>
